.record[data-v-b00c07d6] {
  font-size: 14px;
  color: #333;
  font-weight: 600;
  margin-bottom: 10px;
}
.record[data-v-b00c07d6]::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}
.el-form-item__label[data-v-b00c07d6] {
  font-size: 13px;
}
.content[data-v-b00c07d6] {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  position: relative;
}
.content span[data-v-b00c07d6] {
  line-height: 28px;
  font-size: 13px;
}
.content tr[data-v-b00c07d6] {
  padding: 0;
  margin: 0;
}
.content td[data-v-b00c07d6] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  line-height: 25px;
  padding: 0 6px;
  color: #333;
  font-size: 13px;
}
.center[data-v-b00c07d6] {
  text-align: center;
}
[data-v-b00c07d6] .el-textarea__inner {
  min-height: 80px !important;
}
table[data-v-b00c07d6] .el-input__wrapper {
  box-shadow: none;
  background: none;
  border: none;
}
.el-form--inline .el-form-item[data-v-b00c07d6]:nth-child(2n) {
  margin-right: 0;
}
.el-form--inline .el-form-item[data-v-b00c07d6]:last-child {
  margin-right: 0;
}
.archives_up[data-v-b00c07d6] {
  width: 96%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.bigimg[data-v-b00c07d6] {
  margin-top: 19px;
}
.bigimg button[data-v-b00c07d6] {
  transform: translateY(-10px);
  margin-left: 20px;
  cursor: pointer;
}
.bigimg input[data-v-b00c07d6] {
  width: 98px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.pic[data-v-b00c07d6] {
  display: flex;
}
.del_btn[data-v-b00c07d6] {
  position: relative;
  top: 0;
  padding-right: 10px;
  color: red;
  cursor: pointer;
}